import styles from "./index.module.scss";

export default function Cards() {
  return (
    <section className={`${styles.cards} container`}>

      <div className={styles["cards-heading"]}>
        <h2>Our Branding</h2>
      </div>
      <div className={styles["cards-container"]}>
        <div className={styles["card"]}>
          <h3>Quality Assurance</h3>
          <p>We adhere to the highest standards of education, ensuring that our curriculum, teaching methodologies, and safety protocols are all aligned with international best practice.</p>
        </div>
        <div className={styles["card"]}>
          <h3>Distinctive Branding </h3>
          <p>Our brand identity is characterized by a vibrant and child-friendly visual identity, reflecting our commitment to creating a welcoming and inclusive environment for children and families.</p>
        </div>
        <div className={styles["card"]}>
          <h3>Professional Team</h3>
          <p>Our team of educators & staff are dedicated professionals with a passion for ensuring a supportive and enriching experience for every child.</p>
        </div>
      </div>

    </section>
  );
}
