import { useForm } from "react-hook-form";

export function useHookForm(props) {
  const { isFullName, isEmail, isMessage, isPhone, defaultValues, values } =
    props || {};

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    setValue,
    getValues,
    watch,
    trigger,
    clearErrors,
  } = useForm({ mode: "onBlur", defaultValues, values });

  const phoneRegister =
    isPhone &&
    register("mobile_number", {
      onChange: (e) => {
        clearErrors("mobile_number");
        const phoneNumber = e.target.value;
        const trimmedNumber = phoneNumber.replace(/\D/g, "");
        setValue("mobile_number", trimmedNumber);
      },
      required: {
        value: true,
        message: "mobile number is required",
      },
      validate: (value) => {
        const regex = /^01[0125][0-9]{8}$/;
        return regex.test(value) || "Invalid mobile number";
      },
    });

  const fullNameRegister =
    isFullName &&
    register("full_name", {
      onChange: (e) => {
        clearErrors("full_name");
        const full_name = e.target.value;
        const full_nameRegex =
          /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
        const displayValue = full_name
          .replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, "")
          .replace(/\s+/g, " ");
        if (full_nameRegex.test(displayValue)) {
          setValue("full_name", displayValue);
        } else {
          setValue("full_name", displayValue.trim());
        }
        if (!e.target.value) {
          resetField("full_name");
        }
      },
      required: {
        value: true,
        message: "Full name is required",
      },
      maxLength: {
        value: 20,
        message: "Max Characters has been exceeded",
      },
    });

  const emailRegister =
    isEmail &&
    register("email", {
      onChange: (e) => {
        clearErrors("email");
        setValue("email", e.target.value);
        clearErrors("email");
        if (!e.target.value) {
          resetField("email");
        }
      },
      required: {
        value: true,
        message: "Email is required",
      },
      validate: (value) => {
        if (!value) {
          return true;
        } else {
          const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          return regex.test(value) || "Email is not valid";
        }
      },
    });

  const messageRegister =
    isMessage &&
    register("message", {
      required: {
        value: true,
        message: "you must write a message",
      },
    });
  return {
    handleSubmit,
    errors,
    isDirty,
    isValid,
    emailRegister,
    phoneRegister,
    fullNameRegister,
    messageRegister,
    getValues,
    watch,
    dirtyFields,
    isSubmitting,
    clearErrors,
    setValue,
    register,
  };
}
