import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { useLocation } from "react-router-dom";

export default function Layout({ children }) {
  const location = useLocation();

  return (
    <>
      <Navbar />
      <main>{children}</main>
      {!location.pathname.includes("contact-us") && <Footer />}
    </>
  );
}
