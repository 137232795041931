import React from 'react'
import SajaFamily from '../../components/sajaFamily'
import heroImage from '../../assets/images/OrganizationTeam.png'
import Hero from './../../components/brands/Hero/index';
const WhoWeAre = () => {
    const heroData = {
        subHeading: "Who we are",
        heading: "Meet Our Team",
        description: 'At Saja, our educational approach goes beyond traditional academics, focusing on the full development of each student. We aim to foster a love for learning by creating an environment where students are encouraged to think critically, innovate, and express themselves creatively',
        sectionImg: heroImage,
    };
    return (
        <>
            <Hero data={heroData} />
            <SajaFamily />
        </>

    )
}

export default WhoWeAre
