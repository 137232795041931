import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';
export default function AllNews() {
	const [oldNews, setOldNews] = useState(null);
	const [first, setFirst] = useState(null);
	const [others, setOthers] = useState(null);

	async function getLatestNews() {
		try {
			const { data } = await axios.get('https://app.saja-edu.com/api/content/news/latest');

			setFirst(data[0]);
			setOthers(data.slice(1));
		} catch (error) {
			console.log(error);
		}
	}
	async function getAllNews() {
		try {
			const { data } = await axios.get('https://app.saja-edu.com/api/content/news/list');
			setOldNews(data.slice(0, -6).slice(-8).reverse());
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getAllNews();
		getLatestNews();
	}, []);
	return (
		<>	{
			oldNews ? < section className={styles['news']} >
				<div className={styles['news__latest']}>
					{first ? (
						<>
							<Link to={`./singlenews/${first.id}`} className={styles['news__first']}>
								<img src={first.image} alt={first.title} />
								<div className={styles['news__first__description']}>
									<h1>{first.title}</h1>
									<h2>{first.description}</h2>
								</div>
							</Link>
							<div className={styles['news__others']}>
								<div className={styles['news__others__heading']}>
									<h1>Latest</h1>
								</div>
								{others
									? others.map((item, idx) => (
										<div key={idx} className={styles['news__others__item']}>
											<img src={item.image} alt={item.title} />
											<div className={styles['news__others__info']}>
												<h2>{item.created_at.split('T')[0]}</h2>
												<h1>{item.title}</h1>
												<p className={styles['description']}>
													{item.description.split(' ').slice(0, 10).join(' ')}{' '}
													<span>
														<Link to={`./singlenews/${item.id}`}>Read More</Link>
													</span>
												</p>
											</div>
										</div>
									))
									: ' '}
							</div>
						</>
					) : (
						''
					)}
				</div>
				{oldNews?.length > 0
					? <div className={styles['news__old']}>
						<div className={styles['news__old__heading']}>
							<h1>News</h1>
						</div>
						<div className={styles['news__old__items']}>
							{oldNews.map((item, idx) => (
								<Link to={`./singlenews/${item.id}`} key={idx} className={styles['news__old__item']}>
									<img src={item.image} alt={item.title} />
									<div className={styles['news__old__info']}>
										<h1>{item.title}</h1>
										<p>{item.description.split(' ').slice(0, 20).join(' ')}</p>
										{/* <div className={styles['news__old__about']}>
                                    </div> */}
									</div>
								</Link>
							))
							}
						</div>
					</div> : " "}

			</section >
				: <section className={styles['loading']}> <Circles
					height="80"
					width="80"
					color="#C1C1BE"
					ariaLabel="circles-loading"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
				/>
				</section>
		}
		</>);
}
