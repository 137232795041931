import React from 'react'
import AllNews from './../../components/news/index';
import Events from './../../components/events/index';

export default function News() {
  return (
    <>
      <AllNews />
      <Events />
    </>

  )
}
