import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
export default function Events() {

    const [allEvents, setAllEvents] = useState(null)
    async function getAllEvents() {
        const { data } = await axios.get("https://app.saja-edu.com/api/content/events/list")
        setAllEvents(data.slice(-8).reverse())
        console.log(allEvents)
    }
    useEffect(() => {
        getAllEvents()
    }, [])
    return (
        <>{(allEvents?.length > 0) ? <section className={styles['events']}>

            <div className={styles['events__cards']}>
                <div className={styles['events__heading']}>
                    <h1>Event</h1>
                </div>
                {allEvents.map((item, idx) => (
                    <div key={idx} className={styles['events__card']}>
                        <img src={item.image} alt="" />
                        <div className={styles['events__card__info']}>
                            <h2>{item.date}</h2>
                            <h1>{item.title}</h1>
                            <p className={styles['description']} >{item.description.split(" ").slice(0, 10).join(" ")} <span>
                                <Link href="">Read More</Link>
                            </span></p>
                        </div>
                    </div>
                ))
                }
            </div>
        </section>
            : ""}</>
    )
}
