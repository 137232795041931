import React, { useEffect } from "react";
import corePillarsLine from "../../assets/images/core-pillars-line.svg";
import Hero from "../../components/brands/Hero";
import currDevelopment from "./../../assets/images/curriculum-development.png";
import educationalServices from "./../../assets/images/educational-services.png";
import evaluating from "./../../assets/images/evaluating.png";
import facilityDesign from "./../../assets/images/facility-design.png";
import philosophy from "./../../assets/images/philosophy.png";
import servicesHero from "./../../assets/images/services-hero.png";
import staffRecruting from "./../../assets/images/staff-recruting.png";
import learnerCentric from "./../../assets/images/learner-centric.png";
import playBased from "./../../assets/images/play-based.png";
import parents from "./../../assets/images/parents.png";
import star from "../../assets/images/star.png";

import styles from "./index.module.scss";
import { useLocation } from "react-router-dom";

const Services = () => {
  const location = useLocation();

  const heroData = {
    subHeading: "Our Services",
    heading: "Aiming to nurture every aspect of a child's growth",
    description: `Comprehensive Solutions to Power Your Educational Journey, From Custom Platforms to Engaging Learning Tools`,
    sectionImg: servicesHero,
  };
  const philosophyData = {
    subHeading: "Our Philosophy",
    heading: "Holistic Education for Mind and Heart",
    description: `At Saja, we believe in nurturing the whole learner, ensuring that education is about growing both minds and hearts. Our philosophy centers around 3 Core Pillars`,
    sectionImg: philosophy,
  };
  const cards = [
    {
      subHeading: "Recognizing and Respecting Individuality",
      heading:
        "Every learner is a unique story unfolding. We honor and celebrate this individuality, creating an inclusive environment where every student feels valued and respected.",
    },
    {
      subHeading: "Fostering a Love for Learning",
      heading:
        "Curiosity is the spark that ignites a lifelong passion for learning. We create environments that encourage exploration and inquiry, helping students develop a genuine love for learning that lasts a lifetime.",
    },
    {
      subHeading: "Safe and Inclusive Environment",
      heading:
        "The well-being of our students is our top priority. We ensure that all learners feel secure, supported, and ready to explore new ideas in a safe, inclusive environment.",
    },
  ];
  const secondCards = [
    {
      subHeading: "Learning through Exploration and Play",
      heading:
        "We believe that play is a powerful tool for learning. Through hands-on experiences and active exploration, students discover the world around them in a joyful and engaging way.",
    },
    {
      subHeading: "Developing Social and Emotional Skills",
      heading:
        "We emphasize the development of social and emotional intelligence, understanding that these skills are crucial for overall well-being and success in life.",
    },
    {
      subHeading: "Encouraging Creativity and Imagination",
      heading:
        "Creativity is at the heart of learning at Saja. We nurture creative thinking, empowering students to explore new possibilities and develop innovative mindsets.",
    },
  ];
  const thirdCards = [
    {
      subHeading: "Open Communication Channels",
      heading:
        "We believe that successful education is a collaborative effort. Saja fosters open and transparent communication to ensure parents are informed and involved in their child’s educational journey.",
    },
    {
      subHeading: "Involvement in the Learning Journey",
      heading:
        "Parents are active participants in their child's education. We encourage parental involvement at every stage, creating a strong support system that bridges classroom learning with real-world application.",
    },
  ];
  return (
    <>
      <Hero data={heroData} />
      <div className={`${styles.row} ${styles["first-row"]}`} id="vision">
        <div className={styles["content"]} id="educational" >
          <div className={styles["content-container"]}>
            <h3>Educational Services Across All Ages</h3>
            <p>
              At Saja, we cultivate gardens of knowledge, maintaining
              sustainable quality and fostering vibrant atmospheres where both
              learners and educators flourish. By embracing best practices, we
              elevate process quality and promote ongoing professional growth at
              every stage of education.
            </p>
          </div>
        </div>

        <div className={styles["img-container"]}>
          <img src={educationalServices} alt="Educational Services Image" />
        </div>
      </div>
      <div className={`${styles.row} ${styles["second-row"]}`} id="vision">
        <div className={styles["img-container"]}>
          <img src={currDevelopment} alt="Educational Services Image" />
        </div>
        <div className={styles["content"]} id="curriculum">
          <div className={styles["content-container"]}>
            <h3>Curriculum Development</h3>
            <p>
              We craft impactful learning experiences by blending the latest
              educational techniques with proven best practices, preparing
              students for an ever-evolving world.
            </p>
          </div>
        </div>
      </div>
      <div className={`${styles.row} ${styles["first-row"]}`} id="vision">
        <div className={styles["content"]} id="recruiting">
          <div className={styles["content-container"]}>
            <h3>Staff Recruiting, Training & Development</h3>
            <p>
              We build a team of passionate educators who inspire learners to
              reach for the stars, ensuring that every classroom is a place of
              inspiration, fun, and growth.
            </p>
          </div>
        </div>

        <div className={styles["img-container"]}>
          <img src={staffRecruting} alt="Educational Services Image" />
        </div>
      </div>
      <div className={`${styles.row} ${styles["second-row"]}`} id="vision">
        <div className={styles["img-container"]}>
          <img src={facilityDesign} alt="Educational Services Image" />
        </div>
        <div className={styles["content"]} id="design">
          <div className={styles["content-container"]}>
            <h3>Facility Design, Setup & Management</h3>
            <p>
              Our meticulously designed environments are more than just
              classrooms; they are spaces where curiosity takes root and a love
              for learning blossoms.
            </p>
          </div>
        </div>
      </div>
      <div className={`${styles.row} ${styles["first-row"]}`} id="vision">
        <div className={styles["content"]}>
          <div className={styles["content-container"]}>
            <h3>
              Evaluating Educational Effectiveness, Overseeing Budget & Finances
            </h3>
            <p>
              We build a team of passionate educators who inspire learners to
              reach for the stars, ensuring that every classroom is a place of
              inspiration, fun, and growth.
            </p>
          </div>
        </div>

        <div className={styles["img-container"]}>
          <img src={evaluating} alt="Educational Services Image" />
        </div>
      </div>
      <Hero
        data={philosophyData}
        style={{ marginTop: 0 }}
        imgStyle={{ height: "100%" }}
      />
      <div className={styles["core-pillars"]}>
        <div className={styles["core-pillars-col"]}>
          <img src={corePillarsLine} />
          <p>Core Pillars</p>
          <img src={star} alt="Star" />
        </div>
        <div className={styles["core-pillars-rows"]}>
          <div className={`${styles.row} ${styles["second-row"]}`} id="vision">
            <div className={styles["img-container"]}>
              <img src={learnerCentric} alt="Educational Services Image" />
            </div>
            <div className={styles["content"]} id="approach" >
              <div className={styles["content-container"]}>
                <h3>Learner-Centric Approach</h3>
                <p>
                  Saja embraces a child-centric approach, placing the needs,
                  interests, and well-being of each child at the center of their
                  educational philosophy.
                </p>
              </div>
            </div>
          </div>
          <div className={styles["core-pillars-rows-cards"]}>
            {cards.map((card, index) => (
              <div
                key={index}
                className={styles["core-pillars-rows-cards-card"]}
              >
                <h4>{card.subHeading}</h4>
                <p>{card.heading}</p>
              </div>
            ))}
          </div>
          <div className={`${styles.row} ${styles["second-row"]}`} id="vision">
            <div className={styles["img-container"]}>
              <img src={playBased} alt="Educational Services Image" />
            </div>
            <div className={styles["content"]} id="learning">
              <div className={styles["content-container"]}>
                <h3>Play-based Learning</h3>
                <p>
                  At Saja Education Services, play-based learning is a core
                  component of their educational approach, reflecting their
                  commitment to fostering holistic development in young
                  children.
                </p>
              </div>
            </div>
          </div>
          <div className={styles["core-pillars-rows-cards"]}>
            {secondCards.map((card, index) => (
              <div
                key={index}
                className={styles["core-pillars-rows-cards-card"]}
              >
                <h4>{card.subHeading}</h4>
                <p>{card.heading}</p>
              </div>
            ))}
          </div>
          <div className={`${styles.row} ${styles["second-row"]}`} id="vision">
            <div className={styles["img-container"]}>
              <img src={parents} alt="Educational Services Image" />
            </div>
            <div className={styles["content"]} id="partnership">
              <div className={styles["content-container"]}>
                <h3>Partnership with Parents</h3>
                <p>
                  Recognizing that parents are a child’s first and most
                  influential teachers, Saja fosters close collaboration between
                  educators and families to ensure that each child receives the
                  best possible support for their development.
                </p>
              </div>
            </div>
          </div>
          <div className={styles["core-pillars-rows-cards"]}>
            {thirdCards.map((card, index) => (
              <div
                key={index}
                className={styles['core-pillars-rows-cards-card']}
              >
                <h4>{card.subHeading}</h4>
                <p>{card.heading}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
