import styles from "./index.module.scss";
import closeIcon from "../../assets/icons/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../store/slices/snackbarSlice";
import { useEffect, useRef } from "react";

export function Snackbar() {
  const { display, message, variant } = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();
  const timerId = useRef();
  const handleCloseSnackbar = () => {
    dispatch(
      setSnackbar({
        display: false,
        variant: "",
        message: "",
      })
    );
  };

  useEffect(() => {
    if (display) {
      timerId.current = setTimeout(() => {
        handleCloseSnackbar();
        clearTimeout(timerId);
      }, 5000);
    } else if (timerId?.current) {
      clearTimeout(timerId?.current);
    }
  }, [display]);

  return (
    <div
      className={[
        styles["snackbar"],
        variant === "error" ? styles["snackbar-error"] : "",
        display ? styles["snackbar-show"] : "",
      ].join(" ")}
    >
      <p className={styles["snackbar-text"]}>{message}</p>
      <button
        onClick={handleCloseSnackbar}
        className={styles["snackbar-close"]}
      >
        <img
          className={styles["snackbar-close-icon"]}
          src={closeIcon}
          alt="close"
        />
      </button>
    </div>
  );
}

export default Snackbar;
