import React from "react";
import styles from "./index.module.scss";
import row1Img from "../../assets/images/try.svg";
import row2Img from "../../assets/images/about-row-2.png";
import greyLine from "../../assets/images/grey-line.png";
import star from "../../assets/images/star.png";

import rightArrow from "../../assets/images/rightArrow.png";
import leftArrow from "../../assets/images/leftArrow.png";

import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function AboutCards() {
  const cards = [
    {
      subHeading: "Authenticity",
      heading:
        "Like the roots of a tree, we embed moral values, ethics, and principles into every layer of our educational models, ensuring a strong foundation for generations to come",
    },
    {
      subHeading: "Respect",
      heading:
        "We value every individual as a crucial part of our educational community, fostering a supportive and inclusive environment where everyone’s unique worth is recognized.",
    },
    {
      subHeading: "Integrity",
      heading:
        "We stand as pillars of trust, upholding our responsibilities to develop highly informed and compassionate members of society with unwavering honesty and commitment.",
    },
    {
      subHeading: "Diversity",
      heading:
        "We celebrate the rich mosaic of education, embracing diverse approaches to cater to each learner’s unique path.",
    },
    {
      subHeading: "Excellence",
      heading:
        "We strive for excellence in every aspect of our services, setting the highest standards to deliver top-tier education.",
    },
    {
      subHeading: "Agility",
      heading:
        "Like a river that adapts to its course, we swiftly respond to changes and challenges, ensuring our solutions remain relevant and effective.",
    },
    {
      subHeading: "Sustainability",
      heading:
        "We embrace practices that support the vitality of our ecosystems—ecological, human, and economic—ensuring our impact withstands the test of time.",
    },
  ];

  return (
    <section className={styles["about-cards"]}>
      <div className={`${styles.row} ${styles["first-row"]}`} id="vision">
        <div className={styles["content"]}>
          <div className={styles["content-container"]}>
            <h3>Vision</h3>
            <p>
              We are dedicated to forging a brighter future through education.
              Our vision is to empower every learner to unlock and realize their
              full potential, shaping a world filled with curious minds and
              compassionate hearts.
            </p>
          </div>
        </div>

        <div className={styles["img-container"]}>
          <img src={row1Img} alt="About vision image" />
        </div>
      </div>

      <div className={`${styles.row} ${styles["second-row"]}`} id="mission">
        <div className={styles["img-container"]}>
          <div className={styles["grey-col"]}>
            <img src={greyLine} alt="Grey line" />
            <p>Core Values</p>
            <img src={star} alt="Star" />
          </div>
        </div>

        <div className={styles["rows"]}>
          <div className={styles["row-container"]}>
            <div className={styles["row-container-img"]}>
              <img src={row2Img} alt="About mission image" />
            </div>

            <div className={styles["content"]}>
              <div className={styles["content-container"]}>
                <h3>Mission</h3>
                <p>
                  Our mission is to provide innovative, inclusive, and holistic
                  educational experiences that encourage creativity, critical
                  thinking, and personal growth in every learner.
                </p>
              </div>
            </div>
          </div>

          <div className={styles.swipersec}>
            <h3>
              Empower every learner to unlock and realize their full potential.
            </h3>

            <div className={styles["btns-container"]}>
              <button id="slider-prev-btn" className={styles["btn-prev"]}>

                <img src={rightArrow} alt="" />
              </button>
              <button id="slider-next-btn" className={styles["btn-next"]}>
                <img src={rightArrow} alt="" />
              </button>
            </div>
            <div className={styles["cards"]}>
              <Swiper
                modules={[Navigation, Pagination, Scrollbar]}
                slidesPerView={1}
                spaceBetween={30}
                breakpoints={{
                  500: {
                    slidesPerView: 2, // Show 2 slides on medium screens
                    spaceBetween: 20,
                  },
                  1400: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1580: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  }
                }}
                navigation={{
                  nextEl: "#slider-next-btn",
                  prevEl: "#slider-prev-btn",
                }}
              >
                <div className={styles["cards-wrapper"]}>
                  {cards.map((card, index) => (
                    <SwiperSlide key={index}>
                      <div className={styles["card"]}>
                        <h4>{card.subHeading}</h4>
                        <p>{card.heading}</p>
                      </div>
                    </SwiperSlide>
                  ))}
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
