import React from 'react'
import About from '../../components/about'
import Cards from '../../components/cards'
import Approach from '../../components/approach'
import ReachUs from '../../components/reachUs'
import Hero from '../../components/hero'

export default function Landing() {

  return (
    <>
      <Hero />
      <About />
      <Cards />
      <Approach />
      <ReachUs />
    </>
  )
}
