import React, { useEffect, useState } from 'react';
import member1 from '../../assets/images/member1.png';
import member2 from '../../assets/images/member2.png';
import member3 from '../../assets/images/member3.png';
import member4 from '../../assets/images/member4.png';
import member5 from '../../assets/images/member5.png';
import member6 from '../../assets/images/member6.png';
import member7 from '../../assets/images/member7.png';
import member8 from '../../assets/images/member8.png';
import styles from './index.module.scss';
import axios from 'axios';

const SajaFamily = () => {
	const [family, setFamily] = useState(null);
	async function getSajaFamily() {
		try {
			const { data } = await axios.get('https://app.saja-edu.com/api/content/team/members');
			console.log(data);
			setFamily(data);
		} catch (error) {
			console.log(error);
		}
	}
	const familyMembers = [
		{
			name: 'Ahmed Fekry',
			title: 'Chairman',
			image: member2,
		},
		{
			name: 'Ramez Abdel Maksoud',
			title: 'Vice Chairman ',
			image: member6,
		},
		{
			name: 'Mahmoud ELDEEB',
			title: 'Board Member & CEO',
			image: member8,
		},
		{
			name: 'Yasser Shaheen',
			title: 'Chief Operations Officer ',
			image: member3,
		},
		{
			name: 'Ahmed Shaheen',
			title: 'Chief Financial Officer',
			image: member1,
		},
		{
			name: 'Mona Zaghloul',
			title: 'VP for Education Services',
			image: member4,
		},
		{
			name: 'Nermin ElSotary',
			title: 'VP of Strategy and Planning',
			image: member7,
		},
		{
			name: 'Shaimaa  Hosney',
			title: 'Brand Manager',
			image: member5,
		},
	];
	useEffect(() => {
		console.log(familyMembers);
		getSajaFamily();
	}, []);
	return (
		<>
			{family ? (
				<section className={styles['family']}>
					<div className={styles['family__cardMembers']}>
						{family.map((card, idx) => (
							<a
								href={card.link}
								target="_blank" key={idx} className={styles['family__member']}>
								< div className={styles['image-cover']} >
									<img src={card.image} alt={card.name} />
								</div>
								<h2>{card.name}</h2>
								<h4>{card.title}</h4>
							</a>
						))}
					</div >
				</section >
			) : (
				''
			)}
		</>
	);
};

export default SajaFamily;
