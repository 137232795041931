import styles from "./index.module.scss";
import React from 'react'
import Hero from "../../components/brands/Hero";
import whysajaHero from "./../../assets/images/whysajaHero.png";
import whysajaPlans from "./../../assets/images/whysajaPlans.png";

export default function WhySaja() {
    const heroData = {
        subHeading: "Why Saja",
        heading: "Saja's Commitment to Innovation, Creativity, and Social Responsibility",
        description: `At Saja, our educational approach goes beyond traditional academics, focusing on the full development of each student. We aim to foster a love for learning by creating an environment where students are encouraged to think critically, innovate, and express themselves creatively`,
        sectionImg: whysajaHero,
    };


    return (
        <>
            <Hero data={heroData} />
            <div className={styles['whysaja']}>
                <div className={styles['whysaja__features']}>
                    <div className={styles['whysaja__card']}>
                        <h1>Innovative Curriculum</h1>
                        <p>Our research-grounded curriculum meets the evolving needs of each student, ensuring a joyful and discovery-filled learning journey.</p>
                    </div>
                    <div className={styles['whysaja__card']}>
                        <h1>Qualified and Passionate Educators</h1>
                        <p>Our educators are the heart of Saja, dedicated to nurturing creativity and inspiring learners to imagine and achieve the impossible.</p>
                    </div>
                    <div className={styles['whysaja__card']}>
                        <h1>Adaptive Learning Approaches</h1>
                        <p>We cater to diverse learning styles, unlocking each learner’s potential through flexible and adaptive approaches.</p>
                    </div>
                    <div className={styles['whysaja__card']}>
                        <h1>Continuous Professional Development</h1>
                        <p>We continuously equip our educators with the latest knowledge and teaching methods, ensuring they remain at the forefront of educational innovation.</p>
                    </div>    <div className={styles['whysaja__card']}>
                        <h1>Community Engagement</h1>
                        <p>Saja actively contributes to the well-being of the communities we serve through various engagement programs, creating a positive and lasting impact.</p>
                    </div>    <div className={styles['whysaja__card']}>
                        <h1>Social Impact Partnerships</h1>
                        <p>We partner with local organizations to address community needs, extending our influence beyond the classroom and into the heart of the community.</p>
                    </div>    <div className={styles['whysaja__card']}>
                        <h1>Safe and Supportive Environment</h1>
                        <p>We create warm, inclusive spaces where students feel valued and supported every step of the way.</p>
                    </div>
                </div>
                <div className={styles['whysaja__plans']}>
                    <div className={styles['whysaja__plans__info']}>
                        <h3>Our plans</h3>
                        <h1>Fueled by a passion for growth and innovation, Saja is on a mission to extend its educational services to more young scholars. </h1>
                        <h2>Local and Regional Expansion:</h2>
                        <p>Saja strive to create a meaningful impact on a broader scale.</p>
                        <h2>Commitment to Continuous Improvement:</h2>
                        <p>Drives us to advance and adapt, ready to introduce additional educational services that meet the ever-changing landscape of education.</p>
                    </div>
                    <img src={whysajaPlans} alt="plans" />
                </div>

            </div>
        </>

    )
}
