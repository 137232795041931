import styles from "./index.module.scss";

import Icon from "./../../assets/icons/brands_sub-heading-icon.png";
import Line from "./../../assets/images/brands_line.webp";

export default function Hero({data}) {

  const {subHeading, heading, description} = data;
  return (
    <section className={styles["hero"]}>
      <div className={styles["hero-content"]}>
        <div className={styles["sub-heading"]}>
          <img src={Icon} alt="" />
          <h3>{subHeading}</h3>
          <img src={Line} alt="" />
        </div>
        <h1 className={styles["heading"]}>{heading}</h1>
        <h3 className={styles["desc"]}>{description}</h3>
      </div>
    </section>
  );
}
