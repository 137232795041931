import styles from './index.module.scss';
import arrow from '../../assets/icons/right-arrow-white.svg';
import reachUs from '../../assets/images/reach-us.webp';
import { useNavigate } from 'react-router-dom';

export default function ReachUs() {
  const navigate = useNavigate();

  return (
    <section className={styles['reach-us']}>
      <h2 className={styles['reach-us-title']}>&quot;Here, roots of knowledge intertwine, creating lifelong learners, ready to blossom and run the world.&quot;</h2>
      <p className={styles['reach-us-desc']}>Saja approach goes beyond traditional teaching methods by focusing on the complete development of a child—cognitively, emotionally, socially, and physically.</p>
      <button onClick={() => {
        navigate("/contact-us")
      }} className={styles['reach-us-action']}>
        <span className={styles['reach-us-action-text']}>reach us</span>
        <span className={styles['reach-us-action-icon']}>
          <img src={arrow} className={styles['reach-us-action-arrow']} alt='' />
        </span>
      </button>
      <img src={reachUs} className={styles['reach-us-image']} alt='' />
    </section>
  )
}
