import React from "react";
import cube from "../../../assets/icons/brands_cubes-icon.png";
import Cube from "../Cube";
import styles from "./index.module.scss";

import facebook from "../../../assets/icons/facebook.webp";
import instagram from "../../../assets/icons/instagram.webp";
import linkedin from "../../../assets/icons/linkedin.webp";
import x from "../../../assets/icons/x.webp";
import rightArrow from "../../../assets/icons/right-arrow.webp";

const Preschool = ({
  subHeading,
  heading,
  description,
  mainImgSrc,
  subImg,
  id,
  linkedinLink,
  facebookLink,
  instagramLink,
  webLink,
  xLink, // Changed from `x` to `xLink` for clarity
}) => {
  const cubeData = [
    {
      imgSrc: cube,
      text: "Personalized Education",
    },
    {
      imgSrc: cube,
      text: "Academic Growth",
    },
    {
      imgSrc: cube,
      text: "Love for Learning",
    },
  ];

  return (
    <div className={styles["preschool"]} id={id}>
      <div className={styles["left"]}>
        <div className={`${styles.header} container`}>
          <h3>{subHeading}</h3>
          <h2>{heading}</h2>
        </div>
        <div className={styles["img-content-container"]}>
          {webLink && (
            <div className={styles["rightArrow"]}>
              <a href={webLink} target="_blank" rel="noopener noreferrer">
                <img src={rightArrow} alt="Right Arrow" />
              </a>
            </div>
          )}
          <div className={styles["img-container"]}>
            <img
              src={mainImgSrc}
              alt={heading}
              className={styles["preschool__image"]}
            />
          </div>

          <div className={styles["content-container"]}>
            <img src={subImg} alt="Sub-Image" />

            <div className={styles["social-icons"]}>
              {facebookLink && (
                <a
                  href={facebookLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} alt="Facebook" />
                </a>
              )}

              {instagramLink && (
                <a
                  href={instagramLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagram} alt="Instagram" />
                </a>
              )}

              {linkedinLink && (
                <a
                  href={linkedinLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="LinkedIn" />
                </a>
              )}

              {xLink && (
                <a href={xLink} target="_blank" rel="noopener noreferrer">
                  <img src={x} alt="X" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.right}`}>
        <p
          dangerouslySetInnerHTML={{
            __html: description.replace(/\n/g, "<br/>"),
          }}
        />
      </div>
    </div>
  );
};

export default Preschool;
