import { useState } from "react";
import styles from "./index.module.scss";

export function FormInput({
  formRegister,
  icon,
  name,
  placeholder,
  label,
  value,
  maxLength,
  error,
  inputMode,
  max,
  disabled,
}) {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <div
      className={styles["container"]}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => setIsFocused(false)}
    >
      {label && (
        <label htmlFor={name} className={"input-label"}>
          {!value && !isFocused ? label : ""}
        </label>
      )}
      <div
        className={[
          styles["input-container"],
          error ? styles["input-container-error"] : "",
        ].join(" ")}
      >
        <input
          id={name}
          name={name}
          type={"text"}
          {...formRegister}
          placeholder={placeholder ? placeholder : ""}
          maxLength={max}
          autoComplete="off"
          value={value}
          inputMode={inputMode}
          max={maxLength}
          disabled={disabled}
        />
        {icon && icon}
      </div>
      {error && <p className={styles["form-error"]}>{error?.message}</p>}
    </div>
  );
}

export default FormInput;
