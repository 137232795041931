import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import facebook from '../../assets/icons/facebook.svg';
import instagram from '../../assets/icons/instagram.svg';
import linkedin from '../../assets/icons/linkedin.svg';
import x from '../../assets/icons/x.svg';
import { Circles } from 'react-loader-spinner';
export default function SingleNews() {
	const { id } = useParams();
	const [singleNews, setSingleNews] = useState(null);
	console.log(id);
	async function getSingleNews() {
		try {
			const { data } = await axios.get(`https://app.saja-edu.com/api/content/news/${id}`);
			console.log(data);
			setSingleNews(data);
		} catch (error) {
			console.log(error);
		}
	}
	useEffect(() => {
		getSingleNews();
	}, []);
	return (
		<>
			{singleNews ? (
				<section className={styles['singlenews']}>
					<div className={styles['singlenews__info']}>
						<h1 className={styles['singlenews__title']}>{singleNews.title}</h1>
						<img src={singleNews.image} alt={singleNews.title} />
						<h2 className={styles['singlenews__header']}>{singleNews.description.split(' ').slice(0, 5).join(' ')}</h2>
						<p className={styles['singlenews__description']}>{singleNews.description}</p>
						<div className={styles['singlenews__social']}>
							<h1>Share on social media</h1>
							<div className={styles['singlenews__links']}>
								<a href='https://www.facebook.com/share/z5qbeWBkJsbuiabA/?mibextid=qi2Omg' target='_blank' className={styles['singlenews__link']}>
									<img src={facebook} alt='facebook' />
								</a>
								<a href='https://www.instagram.com/saja_education?igsh=MXNzcjRkaXRtcGxzZA==' target='_blank' className={styles['singlenews__link']}>
									<img src={instagram} alt='instgram' />
								</a>
								<a href='https://www.linkedin.com/company/saja-education/' target='_blank' className={styles['singlenews__link']}>
									<img src={linkedin} alt='linkedin' />
								</a>
								<a href='https://x.com/SajaEduServices?s=08' target='_blank' className={styles['singlenews__link']}>
									<img src={x} alt='x' />
								</a>
							</div>
						</div>
					</div>
				</section>
			) : (
				<section className={styles['loading']}> <Circles
					height="80"
					width="80"
					color="#C1C1BE"
					ariaLabel="circles-loading"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
				/>
				</section>
			)}
		</>
	);
}
