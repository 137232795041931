import { configureStore } from "@reduxjs/toolkit";
import snackbarSlice from "./slices/snackbarSlice";

export const store = configureStore({
  reducer: {
    snackbar: snackbarSlice,
  },
});

export default store;
