import React from "react";
import About from "../../components/about";
import Approach from "../../components/approach";
import ReachUs from "../../components/reachUs";
import Hero from "../../components/brands/Hero";
import Cards from "../../components/brands/Cards";
import Preschool from "../../components/brands/Preschool";

import prescoolImg1 from "./../../assets/images/preschool-1.png";
import prescoolImg2 from "./../../assets/images/preschool-2.webp";
import prescoolImg3 from "./../../assets/images/preschool-3.webp";

import prescoolSubImg1 from "./../../assets/images/brands_sub-img-1.svg";
import prescoolSubImg2 from "./../../assets/images/brands_sub-img-2.svg";
import prescoolSubImg3 from "./../../assets/images/brands_sub-img-3.png";

import hero from "./../../assets/images/brands-hero.webp";

export default function Brands() {
  const preschoolData = [
    {
      subHeading: "Mada Academy",
      heading: "YOUR CHILD HAS IT ALL",
      description: `Mada International Preschool is a forward-looking preschool with a professionally developed curriculum and individualized approach aiming to bring out the best in every child from 3 months to 5 years of age.

      We've combined worldwide acknowledged learning approaches along with our 15 years of experience in raising little ones…mixing tools from 21st century, Montessori, and creative learning approaches to expand the potential of your child through our nurturing, dynamic, & creative classrooms and activities. At Mada your child is guaranteed to reach and expand their full potential. At Mada "Your child has it all`,
      mainImgSrc: prescoolImg1,
      subImg: prescoolSubImg1,
      id: "mada",
      linkedinLink: "https://www.linkedin.com/company/mada-nursery-school",
      facebookLink: "https://www.facebook.com/mada.nursery.school",
      instagramLink: "https://www.instagram.com/mada.nursery.school",
      webLink: "https://mada-academy.com/",
    },
    {
      subHeading: "Meadows",
      heading: "Empowering little minds",
      description: `At Meadows, we empower young learners to explore, grow, and shape a brighter future through play-based learning and nurturing their curiosity and empathy.`,
      mainImgSrc: prescoolImg2,
      subImg: prescoolSubImg2,
      id: "meadows",
      linkedinLink: "https://www.linkedin.com/company/madaacademy/",
      facebookLink:
        "https://www.facebook.com/madanewcairo?mibextid=LQQJ4d&rdid=JGXCqNfpm2LPijte&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FaYgHXdUdQyRjYc4w%2F%3Fmibextid%3DLQQJ4d",
      instagramLink: "https://www.instagram.com/mada_international_preschool/",
      webLink: "http://www.meadows-kids.com",
    },
    {
      subHeading: "Stempowers",
      heading: "Unleash young minds' potentials",
      description: `In STEMPOWERS we prioritize emotional well-being, we create a learning environment where students feel safe to take risks, make mistakes, and learn from their experiences. This fosters a growth mindset that is essential for innovation and problem-solving.`,
      mainImgSrc: prescoolImg3,
      subImg: prescoolSubImg3,
      id: "stempowers",
      facebookLink:
        "https://www.facebook.com/people/Stempowers/61561353042733/",
      instagramLink: "https://www.instagram.com/stem.powers/",
      webLink: "https://stempowers.me/",
    },
  ];

  const heroData = {
    subHeading: "Our Brands",
    heading: "Empowering Learning Through Educational Brands",
    description: `Innovative platforms dedicated to enhancing learning and development across various educational fields .`,
    sectionImg: hero,
  };

  return (
    <>
      <Hero data={heroData} />
      <Cards />
      {preschoolData.map((item, index) => (
        <Preschool
          key={index}
          subHeading={item.subHeading}
          heading={item.heading}
          description={item.description}
          mainImgSrc={item.mainImgSrc}
          subImg={item.subImg}
          linkedinLink={item.linkedinLink}
          facebookLink={item.facebookLink}
          instagramLink={item.instagramLink}
          webLink={item.webLink}
          id={item.id}
        />
      ))}
    </>
  );
}
